export default [
  {
    header: "MANTENIMIENTO",
  },
  {
    title: "Catálogos",
    icon: "PackageIcon",
    children: [
      {
        title: "Banco",
        route: "catalogos-bancos",
        icon: "CircleIcon",
      },
      {
        title: "Categoria",
        route: "catalogos-categoria",
        icon: "CircleIcon",
      },
      {
        title: 'Centro de Trabajo',
        route: 'catalogos-centro-trabajo',
        icon: 'CircleIcon',
      },
      {
        title: 'Colonias',
        route: 'catalogos-colonias',
        icon: 'CircleIcon',
      },
      {
        title: "Ejercicio",
        route: "catalogos-ejercicios",
        icon: "CircleIcon",
      },
      {
        title: "Entidades",
        route: "catalogos-entidades",
        icon: "CircleIcon",
      },
      {
        title: "Estado Civil",
        route: "catalogos-estado-civil",
        icon: "CircleIcon",
      },
      {
        title: 'Estatus de Cobertura',
        route: 'catalogos-cobertura-estatus',
        icon: 'CircleIcon',
      },        
      {
        title: "Familiar",
        route: "catalogos-familiares",
        icon: "CircleIcon",
      },
      {
        title: "Forma Pago",
        route: "catalogos-formas-pagos",
        icon: "CircleIcon",
      },
      {
        title: "Función",
        route: "catalogos-funciones",
        icon: "CircleIcon",
      },
      {
        title: "Genero",
        route: "catalogos-genero",
        icon: "CircleIcon",
      },
      // {
      //   title: "Incidencia",
      //   route: "catalogos-incidencia",
      //   icon: "CircleIcon",
      // },     
      {
        title: "Localidades",
        route: "catalogos-localidades",
        icon: "CircleIcon",
      },      
      {
        title: 'Motivo Alta',
        route: 'catalogos-motivo-alta',
        icon: 'CircleIcon',
      },
      {
        title: "Motivo Baja",
        route: "catalogos-motivos-bajas",
        icon: "CircleIcon",
      },
      {
        title: "Motivo Cancelación Cheque",
        route: "catalogos-motivo-cancelacion-cheque",
        icon: "CircleIcon",
      },
      {
        title: "Motivo de Movimiento",
        route: "catalogos-motivo-movimiento",
        icon: "CircleIcon",
      },        
      {
        title: "Motivo Vacante",
        route: "catalogos-motivos-vacantes",
        icon: "CircleIcon",
      }, 
      {
        title: "Movimiento",
        route: "catalogos-movimiento",
        icon: "CircleIcon",
      },
      {
        title: 'Movimiento Estatus',
        route: 'catalogos-movimiento-estatus',
        icon: 'CircleIcon',
      },
      {
        title: "Municipios",
        route: "catalogos-municipios",
        icon: "CircleIcon",
      },
      {
        title: "Nacionalidades",
        route: "catalogos-nacionalidad",
        icon: "CircleIcon",
      },
      {
        title: "Origen Recurso",
        route: "catalogos-origen-recursos",
        icon: "CircleIcon",
      },
      {
        title: 'Parentesco',
        route: 'catalogos-parentesco',
        icon: 'CircleIcon',
      },      
      {
        title: 'Plaza',
        route: 'catalogos-plaza',
        icon: 'CircleIcon',
      },
      {
        title: "Prioridad Proceso",
        route: "catalogos-prioridad-proceso",
        icon: "CircleIcon",
      },
      {
        title: 'Registro Patronal',
        route: 'catalogos-registro-patronal',
        icon: 'CircleIcon',
      },
      {
        title: "Retención Ingreso",
        route: "catalogos-retenciones-ingresos",
        icon: "CircleIcon",
      },           
      {
        title: "Sat Motivos Cancelaciones",
        route: "catalogos-sat-motivos-cancelaciones",
        icon: "CircleIcon",
      },           
      {
        title: "Sat Patronal",
        route: "catalogos-sat-patronales",
        icon: "CircleIcon",
      },      
      {
        title: "Sat Patronal Centro Trabajo",
        route: "catalogos-sat-patronal-centro-trabajo",
        icon: "CircleIcon",
      },
      {
        title: "Sat Periodicidad Pago",
        route: "catalogos-sat-periodicidad-pago",
        icon: "CircleIcon",
      },
      {
        title: 'Sat Régimen',
        route: 'catalogos-sat-regimen',
        icon: 'CircleIcon',
      },
      {
        title: 'Sat Riesgo de Puesto',
        route: 'catalogos-sat-riesgo-puesto',
        icon: 'CircleIcon',
      },
      {
        title: "Sat Tipo Nomina",
        route: "catalogos-sat-tipos-nominas",
        icon: "CircleIcon",
      },
      {
        title: "Sexo",
        route: "catalogos-sexo",
        icon: "CircleIcon",
      },
      {
        title: "Sindicatos",
        route: "catalogos-sindicato",
        icon: "CircleIcon",
      },
      {
        title: 'Tabla Incidencia',
        route: 'catalogos-tabla-incidencia',
        icon: 'CircleIcon',
      },
      {
        title: "Tipo Aplicación",
        route: "catalogos-tipo-aplicacion",
        icon: "CircleIcon",
      },
      {
        title: "Tipo Centro de Trabajo",
        route: "catalogos-tipo-centro-trabajo",
        icon: "CircleIcon",
      },
      {
        title: "Tipo Clave",
        route: "catalogos-tipo-clave",
        icon: "CircleIcon",
      }, 
      {
        title: "Tipo de Compensación",
        route: "catalogos-tipo-compensacion",
        icon: "CircleIcon",
      },             
      {
        title: 'Tipo de Concepto',
        route: 'catalogos-tipo-concepto',
        icon: 'CircleIcon',
      },
      {
        title: "Tipo de Contrato",
        route: "catalogos-tipo-contrato",
        icon: "CircleIcon",
      },
      {
        title: "Tipo de Empleado",
        route: "catalogos-tipo-empleado",
        icon: "CircleIcon",
      },
      {
        title: "Tipo de Función",
        route: "catalogos-tipo-funcion",
        icon: "CircleIcon",
      },
      {
        title: "Tipo de Juicio",
        route: "catalogos-tipo-juicio",
        icon: "CircleIcon",
      }, 
      {
        title: "Tipo de Movimiento",
        route: "catalogos-tipo-movimiento",
        icon: "CircleIcon",
      },
      {
        title: "Tipo de Nomina",
        route: "catalogos-tipos-nominas",
        icon: "CircleIcon",
      },
      {
        title: "Zona",
        route: "catalogos-zona",
        icon: "CircleIcon",
      }, 
      {
        title: "Zona Económica",
        route: "catalogos-zona-economica",
        icon: "CircleIcon",
      },              
    ],
  },
];
