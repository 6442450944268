<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!--<bookmarks />-->
      <b-navbar-nav class="nav">
        <b-nav-item>
          <h4 class="mt-1 text-primary font-weight-bold">{{ appName }}</h4>
        </b-nav-item>
      </b-navbar-nav>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--<locale />-->
      <dark-Toggler class="d-none d-lg-block" />
      <!--<search-bar />
      <cart-dropdown />-->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,BNavItem
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    BNavItem,
    Bookmarks,
    Locale,
    DarkToggler,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

}
</script>
